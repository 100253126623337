import React from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";

const container = css`
  overflow: auto;
  width: 90%;
  margin: 0 auto;
  height: 160px;
  padding: 40px;
  border: 1px solid ${color.black};
  line-height: 1.5;
  font-size: ${font.textSize};
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
    padding: 16px;
  }
`;

const sectionTitle = css`
  text-align: center;
  font-weight: normal;
  font-size: ${font.textSize};
`;

const sectionText = css`
  padding-top: 40px;
`;

const subTitle = css`
  padding-top: 24px;
  font-weight: bold;
`;

const subText = css`
  padding-top: 8px;
`;

export const PrivacyPolicyBox = () => {
  return (
    <section css={container}>
      <h3 css={sectionTitle}>個人情報保護方針</h3>
      <p css={sectionText}>
        奈良県医師協同組合（以下「当組合」といいます）は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全組合員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。
      </p>
      <p css={subTitle}>個人情報の管理</p>
      <p css={subText}>
        当組合は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・組合員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
      </p>
      <p css={subTitle}>個人情報の利用目的</p>
      <p css={subText}>
        お客さまからお預かりした個人情報は、当組合からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。
      </p>
      <p css={subTitle}>個人情報の第三者への開示・提供の禁止</p>
      <p css={subText}>
        当組合は、お客さまよりお預かりした個人情報を適切に管理し、本人の同意や法令に基づいた開示以外で、個人情報を第三者に開示いたしません。
      </p>
      <p css={subTitle}>個人情報の安全対策</p>
      <p css={subText}>
        当組合は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
      </p>
      <p css={subTitle}>ご本人の照会</p>
      <p css={subText}>
        お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
      </p>
      <p css={subTitle}>法令、規範の遵守と見直し</p>
      <p css={subText}>
        当組合は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
      </p>
      <p css={subTitle}>お問い合せ</p>
      <p css={subText}>
        当組合の個人情報の取扱に関するお問い合せは下記までご連絡ください。
      </p>
      <p css={subText}>〒630-8013</p>
      <p css={subText}>奈良市三条大路1-1-87 奈良市役所前三和ビル2F</p>
      <p css={subText}>Tel: 0742-34-7991 Fax: 0742-34-7992</p>
    </section>
  );
};
