import React, { useState, useEffect } from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";
import { Layout } from "../components/Layout";
import { Head } from "../components/Head";
import { Form } from "../components/Form";

const wrapper = css`
  width: 100%;
  margin: 0 auto;
`

const titleAria = css`
  text-align: center;
  padding-top: 95px;
  padding-bottom: 100px;
  ${mediaQuery[smartPhone]} {
    padding-top: 109px;
    padding-bottom: 40px;
  }
`

const mainTitle = css`
  font-family: 'Noto Serif JP', serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #8F5E5E;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
    line-height: 17px;
  }
`

const subTitle = css`
  display: block;
  padding: 24px 0;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.NomaltitleSize};
  line-height: 52px;
  color: #5F5450;
  ${mediaQuery[smartPhone]} {
    font-size: 18px;
    line-height: 26px
    padding: 12px 0;
  }
`

const border = css`
  width: 80px;
  height: 2px;
  margin: 0 auto;
  background-color: ${color.gray};
  ${mediaQuery[smartPhone]} {
    width: 60px;
    height: 1px;
  }
`

export default function Contact({ data, location }) {
  const siteMetadata = data?.site?.siteMetadata
  const title = "CONTACT";
  const [pathname, setPathname] = useState("");
  useEffect(() => {
    setPathname(location.pathname);
  }, []);
  return (
    <Layout location={pathname}>
      <Head props={siteMetadata} />
      <div css={wrapper}>
        <div css={titleAria}>
          <h3 css={mainTitle}>Contact</h3>
          <p css={subTitle}>お問い合わせ</p>
          <div css={border}></div>
        </div>
        <Form />
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title,
        siteUrl,
        description
      }
    }
    allMicrocmsNews {
      nodes {
        daytime
        newsImage1 {
          url
        }
        newsImage2 {
          url
        }
        newsImage3 {
          url
        }
        newsImage4 {
          url
        }
        newsText
        newsTitle
        id
      }
    }
  }
`
