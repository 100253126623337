import React from 'react'
import { css } from '@emotion/core'
import { font, color, mediaQuery, smartPhone } from '../constants/styles'
import { FormPrivacyArea } from './FormPrivacyArea'

const formContainer = css`
  width: 90%;
  margin: 0 auto;
`

const inputContainer = css`
  display: flex;
  ${mediaQuery[smartPhone]} {
    margin: 0 auto;
  }
`

const inputTitleContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 310px;
  height: 60px;
  margin-right: 18px;
  background-color: ${color.mainL};
  border-bottom: 1px solid ${color.white};
  color: ${color.white};
  ${mediaQuery[smartPhone]} {
    max-width: 120px;
    min-width: 111px;
    height: 35px;
    margin-right: 5px;
  }
`

const inputTitle = css`
  font-size: ${font.textSize};
  ${mediaQuery[smartPhone]} {
    font-size: 10px;
  }
`

const inputTextAriaTitle = css`
  font-size: ${font.textSize};
  ${mediaQuery[smartPhone]} {
    display: block;
    font-size: 10px;
    padding-left: 15px;
  }
`

const input = css`
  display: block;
  width: 1077px;
  margin: 3px 0;
  border-radius: 4px;
  ${mediaQuery[smartPhone]} {
    min-width: 213px;
    margin: 2px 0;
  }
`

const select = css`
  display: block;
  width: 1077px;
  margin: 3px 0;
  border-radius: 4px;
  ${mediaQuery[smartPhone]} {
    min-width: 220px;
    margin: 1px 0;
  }
`

const textariaContainer = css`
  display: flex;
  ${mediaQuery[smartPhone]} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
`

const inputTextareaContainer = css`
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-color: ${color.mainL};
  color: ${color.white};
  width: 310px;
  height: 464px;
  margin-right: 18px;
  ${mediaQuery[smartPhone]} {
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 35px;
    margin-right: 0;
  }
`

const inputTextarea = css`
  display: block;
  width: 1077px;
  height: 460px;
  margin: 3px 0;
  border-radius: 4px;
  ${mediaQuery[smartPhone]} {
    width: 99%;
    height: 230px;
    margin: 2px 0;
  }
`

export const Form = () => {
  return (
    <form
      css={formContainer}
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/Thanks"
    >
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="bot-field" />
      <input
        type="hidden"
        name="subject"
        value="ホームページからお問い合わせがあります"
      />
      <div css={inputContainer}>
        <div css={inputTitleContainer}>
          <label for="name" css={inputTitle}>
            お名前*
          </label>
        </div>
        <input
          css={input}
          type="text"
          id="name"
          name="name"
          placeholder="例）山田太朗"
          required
        />
      </div>
      <div css={inputContainer}>
        <div css={inputTitleContainer}>
          <label for="email" css={inputTitle}>
            メールアドレス*
          </label>
        </div>
        <input
          css={input}
          type="email"
          id="email"
          name="email"
          placeholder="例）yamada@mail.com"
          pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
          required
        />
      </div>
      <div css={inputContainer}>
        <div css={inputTitleContainer}>
          <label for="tell" css={inputTitle}>
            電話番号
          </label>
        </div>
        <input
          css={input}
          type="text"
          id="tell"
          name="tell"
          placeholder="例）0123456789"
        />
      </div>
      <div css={inputContainer}>
        <div css={inputTitleContainer}>
          <label for="fax" css={inputTitle}>
            FAX番号
          </label>
        </div>
        <input
          css={input}
          type="text"
          id="fax"
          name="fax"
          placeholder="例）0123456789"
        />
      </div>
      <div css={inputContainer}>
        <div css={inputTitleContainer}>
          <label for="postal-code" css={inputTitle}>
            郵便番号
          </label>
        </div>
        <input
          css={input}
          type="text"
          id="postal-code"
          name="postal-code"
          placeholder="例）123-4567"
        />
      </div>
      <div css={inputContainer}>
        <div css={inputTitleContainer}>
          <label for="pref" css={inputTitle}>
            都道府県
          </label>
        </div>
        <select name="pref" id="pref" css={select}>
          <option value="" disabled selected hidden>
            都道府県を選択
          </option>
          <option value="北海道">北海道</option>
          <option value="青森県">青森県</option>
          <option value="岩手県">岩手県</option>
          <option value="宮城県">宮城県</option>
          <option value="秋田県">秋田県</option>
          <option value="山形県">山形県</option>
          <option value="福島県">福島県</option>
          <option value="茨城県">茨城県</option>
          <option value="栃木県">栃木県</option>
          <option value="群馬県">群馬県</option>
          <option value="埼玉県">埼玉県</option>
          <option value="千葉県">千葉県</option>
          <option value="東京都">東京都</option>
          <option value="神奈川県">神奈川県</option>
          <option value="新潟県">新潟県</option>
          <option value="富山県">富山県</option>
          <option value="石川県">石川県</option>
          <option value="福井県">福井県</option>
          <option value="山梨県">山梨県</option>
          <option value="長野県">長野県</option>
          <option value="岐阜県">岐阜県</option>
          <option value="静岡県">静岡県</option>
          <option value="愛知県">愛知県</option>
          <option value="三重県">三重県</option>
          <option value="滋賀県">滋賀県</option>
          <option value="京都府">京都府</option>
          <option value="大阪府">大阪府</option>
          <option value="兵庫県">兵庫県</option>
          <option value="奈良県">奈良県</option>
          <option value="和歌山県">和歌山県</option>
          <option value="鳥取県">鳥取県</option>
          <option value="島根県">島根県</option>
          <option value="岡山県">岡山県</option>
          <option value="広島県">広島県</option>
          <option value="山口県">山口県</option>
          <option value="徳島県">徳島県</option>
          <option value="香川県">香川県</option>
          <option value="愛媛県">愛媛県</option>
          <option value="高知県">高知県</option>
          <option value="福岡県">福岡県</option>
          <option value="佐賀県">佐賀県</option>
          <option value="長崎県">長崎県</option>
          <option value="熊本県">熊本県</option>
          <option value="大分県">大分県</option>
          <option value="宮崎県">宮崎県</option>
          <option value="鹿児島県">鹿児島県</option>
          <option value="沖縄県">沖縄県</option>
        </select>
      </div>
      <div css={inputContainer}>
        <div css={inputTitleContainer}>
          <label for="address" css={inputTitle}>
            住所
          </label>
        </div>
        <input
          css={input}
          type="text"
          id="address"
          name="address"
          placeholder="例）○○市××町 1-2-3"
        />
      </div>
      <div css={inputContainer}>
        <div css={inputTitleContainer}>
          <label for="Organization" css={inputTitle}>
            医療機関名
          </label>
        </div>
        <input
          css={input}
          type="text"
          id="Organization"
          name="Organization"
          placeholder="例）○○クリニック"
        />
      </div>
      <div css={textariaContainer}>
        <div css={inputTextareaContainer}>
          <label for="content" css={inputTextAriaTitle}>
            お問い合わせ内容*
          </label>
        </div>
        <textarea
          css={inputTextarea}
          id="content"
          name="content"
          placeholder="お問い合わせ内容をご記入ください（資料請求等をご希望の方はその旨明記ください）"
          required
        ></textarea>
      </div>
      <FormPrivacyArea />
    </form>
  )
}
