import React from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";
import { PrivacyPolicyBox } from "./PrivacyPolicyBox";
import { Required } from "./Required";

const wrapper = css`
  width: 100%;
`

const container = css`
  width: 90%;
  margin: 0 auto;
  ${mediaQuery[smartPhone]} {
    width: 100%;
  }
`;

const subTitleContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 56px;
  ${mediaQuery[smartPhone]} {
    display: flex;
    padding: 26px 0 8px 0;
  }
`;

const privacyPolicyTitle = css`
  display: block;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.subtitleSize};
  color: #000000;
  font-weight: bold;
  padding-bottom: 16px;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
    padding-bottom: 6px;
  }
`;

const privacyPolicyText = css`
  font-size: ${font.ButtonTextSize};
  line-height: 2;
  text-align: center;
  padding-bottom: 24px;
  ${mediaQuery[smartPhone]} {
    display: block;
    width: 80%;
    margin: 0 auto;
    line-height: 1.5;
  }
`;

const checkBoxContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 64px 0;
`;

const checkBoxLabel = css`
  font-size: ${font.textSize};
  display: inline;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
  }
`;

const checkBox = css`
  font-size: ${font.miniTextSize};
  margin-right: 16px;
  transform: scale(1.4);
`;

const boxContainer = css`
  display: flex;
  justify-content: center;
`;

const buttonContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 62px auto 160px auto;
  ${mediaQuery[smartPhone]} {
    margin: 24px auto 91px auto;
  }
`;

const submitButton = css`
  display: inline-block;
  border-radius: 4px;
  border: ${color.mainL};
  background-color: ${color.mainL};
  color: ${color.white};
  width: 100%;
  padding: 37px 0;
  font-family: 'Noto Serif JP', serif;
  font-size: 30px;
  transition: 0.25s;
  :hover {
    background-color: ${color.black};
    cursor: pointer;
  }
  ${mediaQuery[smartPhone]} {
    padding: 24px 48px;
    font-size: ${font.ButtonTextSize};
  }
`;

export const FormPrivacyArea = () => {
  return (
    <div css={wrapper}>
      <div css={container}>
        <div css={subTitleContainer}>
          <p css={privacyPolicyTitle}>個人情報のお取り扱いについて</p>
        </div>
        <p css={privacyPolicyText}>
          下記文章をご一読いただき、個人情報保護方針に同意の上送信お願いします。
        </p>
        <div css={boxContainer}>
          <PrivacyPolicyBox />
        </div>
        <div css={checkBoxContainer}>
          <Required />
          <p css={checkBoxLabel}>
            <input
              css={checkBox}
              type="checkbox"
              name="agree"
              required
            />
            個人情報保護方針に同意します
          </p>
        </div>
      </div>
      <div css={buttonContainer} className="form-group">
        <button css={submitButton} type="submit">
          お問い合わせを送信
        </button>
      </div>
    </div>
  );
};
