import React from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";

const required = css`
  display: inline-block;
  ont-family: 'Noto Serif JP', serif;
  background-color: ${color.mainL};
  color: ${color.white};
  font-size: ${font.ButtonTextSize};
  padding: 2px 16px;
  margin-right: 12px; 
  border-radius: 2px;
  ${mediaQuery[smartPhone]} {
    font-size: 6px;
    padding: 2px 9px;
    display: flex;
    justify-content: center;
    min-width: 24px;
  }
`;

export const Required = () => {
  return <div css={required}>必須</div>;
};
